import { Technology } from '@/schemas/entities/technology.schema'
import { Checkbox } from '@/shared/ui/base/checkbox'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { Laptop } from 'lucide-react'

export function CheckBoxItem({
    item,
    checked,
    onChange,
}: {
    item: Technology
    checked: boolean
    onChange: (checked: boolean) => void
}) {
    return (
        <div className="flex flex-row items-center space-x-3 space-y-0">
            <Checkbox id={item.slug} checked={checked} onCheckedChange={onChange} />
            <label htmlFor={item.slug} className="cursor-pointer">
                <div className="flex space-x-2 align-middle items-center">
                    {item.logo && <img src={item.logo} alt={item.name} className="w-6 h-6" />}
                    {!item.logo && (
                        <div className="w-6 h-6 bg-slate-200 flex items-center justify-center">
                            {' '}
                            <Laptop strokeWidth={1.5} />{' '}
                        </div>
                    )}
                    <div>
                        <p className="text-sm font-normal hover:underline" translate="no">
                            {' '}
                            {item.name}{' '}
                        </p>
                        <p className="text-xs font-normal text-gray-500">
                            {' '}
                            {formatNumberWithComma(item.companies || 0)} companies{' '}
                        </p>
                    </div>
                </div>
            </label>
        </div>
    )
}
