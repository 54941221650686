import CompanyCard from '@/components/ui/company/company-card'
import CountryCard from '@/components/ui/country/country-card'
import { isChecked } from '@/components/ui/table/columns/column-utils'
import { DataTableColumnHeader } from '@/components/ui/table/data-table-column-header'
import { CompanyFromList } from '@/schemas/entities/companies-from-company-list'
import { Checkbox } from '@/shared/ui/base/checkbox'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { DataTableRowActions } from './data-table-row-actions'

export const hiddenCompanyColumns = {}

export const companyColumns: ColumnDef<CompanyFromList>[] = [
    {
        id: 'select',
        header: ({ table }) => (
            <Checkbox
                checked={isChecked(table)}
                onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
                className="translate-y-[2px]"
            />
        ),
        cell: ({ row }) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                className="translate-y-[2px]"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Company" />,
        meta: {
            name: () => 'Company',
        },
        cell: ({ row }) => {
            return (
                <>
                    {row.original && row.original.company_object && (
                        <CompanyCard company={row.original.company_object} />
                    )}
                </>
            )
        },
    },
    {
        accessorKey: 'revealed_at',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Revealed at" />,
        meta: {
            name: () => 'Revealed at',
        },
        cell: ({ row }) => {
            return <p>{format(row.original.revealed_at, 'MMM dd, yyyy')}</p>
        },
        enableHiding: false,
    },
    {
        accessorKey: 'added_at',
        meta: {
            name: () => 'First time added',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="First time added" />,
        cell: ({ row }) => {
            return <p>{format(row.original.added_at, 'MMM dd, yyyy')}</p>
        },
        enableSorting: false,
    },
    {
        id: 'company_country',
        accessorKey: 'company_object',
        meta: {
            name: () => 'Company Country',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Company Country" />,
        cell: ({ row }) => {
            return (
                <>
                    {row.original.company_object?.country_code && (
                        <CountryCard countryIso={row.original.company_object.country_code} />
                    )}
                </>
            )
        },
        enableSorting: false,
    },

    {
        id: 'industry',
        accessorKey: 'company_object',
        meta: {
            name: () => 'Company Industry',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Company Industry" />,
        cell: ({ row }) => {
            return <p className="max-w-[300px] text-clip font-normal">{row.original.company_object?.industry}</p>
        },
        enableSorting: false,
    },
    {
        id: 'employees',
        accessorKey: 'company_object',
        meta: {
            name: () => 'Company Employees',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Company Employees" />,
        cell: ({ row }) => {
            return (
                <p className="max-w-[300px] text-clip font-normal">
                    {row.original.company_object?.employee_count
                        ? formatNumberWithComma(row.original.company_object.employee_count)
                        : ''}
                </p>
            )
        },
        enableSorting: false,
    },
    {
        id: 'actions',
        cell: ({ row }) => <DataTableRowActions row={row} />,
    },
]
