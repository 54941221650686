import { z } from 'zod'

export enum InvoiceStatus {
    PAID = 'paid',
    DRAFT = 'draft',
    OPEN = 'open',
    REFUNDED = 'refunded',
    UNCOLLECTIBLE = 'uncollectible',
    VOID = 'void',
}

export const invoiceConsumptionSchema = z.object({
    id: z.string().describe('ID of the invoice'),
    status: z.nativeEnum(InvoiceStatus).describe('Status of the invoice stripe.Invoice.status'),
    ui_credits: z.number().default(0).describe('Number of UI credits available'),
    used_ui_credits: z.number().default(0).describe('Number of UI credits used'),
    api_credits: z.number().default(0).describe('Number of API credits available'),
    used_api_credits: z.number().default(0).describe('Number of API credits used'),
    created_at: z.coerce.date().describe('Creation timestamp of the invoice'),
})

export type InvoiceConsumption = z.infer<typeof invoiceConsumptionSchema>
