import { isObjectEmpty } from '@/lib/object-utils'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { CompaniesSearchParams } from '@/schemas/request/companies-search-params.schema'
import { ColumnDef, VisibilityState } from '@tanstack/react-table'
import { useEffect } from 'react'
import { getNewDynamicColumns, companyColumns as initialColumns, TECHNOLOGY_COLUMN_PREFIX } from './columns-company'

const TECHNOLOGY_COLUMNS_POSITION = 8

export function useColumnsVisibilityBasedOnSearch(
    searchedSearchParams: CompaniesSearchParams,
    columnVisibility: VisibilityState,
    columns: ColumnDef<FullCompany>[],
    setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>,
    setColumns: React.Dispatch<React.SetStateAction<ColumnDef<FullCompany>[]>>
) {
    useEffect(() => {
        showOrHideColumnsBasedOnSearchParams()
        addOrRemoveDynamicColumnsBasedOnSearchParams()
    }, [searchedSearchParams])

    const showOrHideColumnsBasedOnSearchParams = () => {
        if (isObjectEmpty(searchedSearchParams.job_filters)) {
            const newColumnVisibility = { ...columnVisibility }
            newColumnVisibility['num_jobs_found'] = false
            setColumnVisibility(newColumnVisibility)
        } else {
            const newColumnVisibility = { ...columnVisibility }
            newColumnVisibility['num_jobs_found'] = true
            setColumnVisibility(newColumnVisibility)
        }
    }

    const addOrRemoveDynamicColumnsBasedOnSearchParams = () => {
        const technologies = [
            ...new Set([
                ...(searchedSearchParams.company_technology_slug_and || []),
                ...(searchedSearchParams.company_technology_slug_or || []),
                ...(searchedSearchParams.expand_technology_slugs || []),
            ]),
        ]

        // Single pass through columns to check both conditions
        const existingTechnologyColumns = new Set(
            columns.filter((column) => column.id?.startsWith(TECHNOLOGY_COLUMN_PREFIX)).map((column) => column.id)
        )

        const needsUpdate =
            technologies.length !== existingTechnologyColumns.size ||
            technologies.some((tech) => !existingTechnologyColumns.has(TECHNOLOGY_COLUMN_PREFIX + tech))

        if (!needsUpdate) return

        const newColumns = [...initialColumns.filter((column) => !column.id?.includes(TECHNOLOGY_COLUMN_PREFIX))]
        const technologyColumnIds: string[] = technologies.map((technology) => TECHNOLOGY_COLUMN_PREFIX + technology)

        setColumns([
            ...newColumns.slice(0, TECHNOLOGY_COLUMNS_POSITION),
            ...getNewDynamicColumns(technologyColumnIds),
            ...newColumns.slice(TECHNOLOGY_COLUMNS_POSITION),
        ])
    }

    return {}
}
