import { useToast } from '@/components/ui/base/use-toast'
import CreateCompanyListButton from '@/pages/home/components/company-list/button-create-company-list'
import { CompanyList } from '@/schemas/entities/company-list.schema'
import { addCompanyToCompanyList, getAllCompanyLists } from '@/services/company_list.service'
import { Button } from '@/shared/ui/base/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/shared/ui/base/dropdown-menu'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { useQuery } from '@tanstack/react-query'
import { ListPlusIcon } from 'lucide-react'

export default function AddCompanyToListButton({ company_name }: { company_name: string }) {
    const { data: lists = [], refetch } = useQuery<CompanyList[]>({
        queryKey: ['company-list'],
        queryFn: getAllCompanyLists,
        staleTime: HOUR_IN_MS,
    })
    const { toast } = useToast()

    const addToList = (listId: number) => {
        addCompanyToCompanyList(listId, [company_name]).then(() => {
            refetch()
            toast({ title: 'Company added to list' })
        })
    }

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="outline" size="sm" className="text-sm font-normal">
                        <ListPlusIcon className="h-4 w-4 md:mr-2" />
                        <p className="hidden lg:inline"> Add to list </p>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[300px]">
                    <DropdownMenuLabel>Add companies to list</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {lists.map((list) => (
                        <DropdownMenuItem
                            key={list.id}
                            onSelect={() => addToList(list.id)}
                            disabled={list.name == 'Companies seen'}
                        >
                            {list.name} ({formatNumberWithComma(list.companies_count)})
                        </DropdownMenuItem>
                    ))}
                    <CreateCompanyListButton onCreate={(id: number) => addToList(id)}>
                        <a className="text-gray-600 cursor-pointer flex flex-row items-center px-2 py-1.5 underline">
                            <p className="text-sm">Create new list</p>
                        </a>
                    </CreateCompanyListButton>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    )
}
