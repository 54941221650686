import { Button } from '@/shared/ui/base/button'
import { Copy } from 'lucide-react'

import { UserWithTeam } from '@/schemas/entities/user'

import { getToken } from '@/services/auth/auth.service'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../../../../../shared/ui/base/dropdown-menu'

export function AdminCurlActions({
    me,
    curl,
    copyCurlToClipboard,
    isImpersonating,
}: {
    me: UserWithTeam
    curl: string
    copyCurlToClipboard: (curl: string, title: string) => void
    isImpersonating: boolean
}) {
    const getCurlWithToken = (curl: string, useUserToken: boolean) => {
        const code = curl
        if (useUserToken) {
            return code.replace('<api_key>', me.token)
        }
        return code.replace('<api_key>', getToken() || '')
    }

    const getCurlWithTokenAndEnv = (curl: string, useUserToken: boolean) => {
        let code = getCurlWithToken(curl, useUserToken)
        code = code.replace('https://api.theirstack.com', '{{api_theirstack_base_url}}')
        code = code + ' \\\n--header "X-Use-ClickHouse: {{use_clickhouse}}"'
        return code
    }

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="outline" size="sm" className="text-sm font-normal">
                        <Copy className="mr-2 h-4 w-4" />
                        cURL
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuItem
                        onSelect={() => {
                            copyCurlToClipboard(getCurlWithToken(curl, true), 'Copied to clipboard')
                        }}
                    >
                        <Copy className="mr-2 h-4 w-4" />
                        cURL with user token
                    </DropdownMenuItem>
                    {isImpersonating && (
                        <DropdownMenuItem
                            onSelect={() => {
                                copyCurlToClipboard(getCurlWithTokenAndEnv(curl, true), 'Copied to clipboard')
                            }}
                        >
                            <img
                                src="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
                                className="h-4 w-4 mr-2"
                            />
                            cURL with ENV var and user token
                        </DropdownMenuItem>
                    )}
                    <DropdownMenuItem
                        onSelect={() => {
                            copyCurlToClipboard(getCurlWithTokenAndEnv(curl, false), 'Copied to clipboard')
                        }}
                    >
                        <img
                            src="https://voyager.postman.com/logo/postman-logo-icon-orange.svg"
                            className="h-4 w-4 mr-2"
                        />
                        cURL with ENV var and admin token
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </>
    )
}
