import { useFetchDataAndTotalResultsSeparatedForTable } from '@/components/hooks/data-table/use-fetch-data-and-total-results-for-table'
import OutOfCreditsBanner from '@/components/ui/banners/out-of-credits-banner'
import { DataTable } from '@/components/ui/table/data-table'
import DataTableInitialState from '@/components/ui/table/data-table-initial-state'
import { DataTablePagination } from '@/components/ui/table/data-table-pagination'
import { Job } from '@/schemas/entities/job.schema'
import { QueryNewJobSearch } from '@/schemas/entities/query-new-job-search.schema'
import { JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import { exportJobsToWebhook, getJobsFromSearchParams } from '@/services/job.service'
import { hiddenJobColumns, jobsColumns } from '../../components/columns-job'
import { useColumnsVisibilityBasedOnSearch } from '../../components/use-columns-visibility-based-on-search'
import { DataTableToolbar } from './data-table-toolbar'

interface DataTableProps {
    search: QueryNewJobSearch
}

export function DataTableWithJobsToolbar({ search }: DataTableProps) {
    const {
        table,
        metadata,
        searchedSP: searchedSearchParams,
        wasFirstSearchTriggered,
        totalResults,
        columnVisibility,
        setColumnVisibility,
        dirtySP: dirtySearchParams,
        isDataFetching,
        isCurrentSearchSearched,
        onChangeFilters,
        setDirtySP: setDirtySearchParams,
        appliedFilters,
        addFilters,
        onChangeFilter,
        onRemoveFilter,
    } = useFetchDataAndTotalResultsSeparatedForTable<JobsSearchParams, Job>(
        search.query,
        getJobsFromSearchParams,
        exportJobsToWebhook,
        jobsColumns,
        hiddenJobColumns,
        search?.auto_search
    )

    useColumnsVisibilityBasedOnSearch(searchedSearchParams, columnVisibility, setColumnVisibility)

    return (
        <>
            {metadata.truncated_results > 0 && <OutOfCreditsBanner hidden_results={metadata.truncated_results} />}
            <DataTableToolbar
                isDataFetching={isDataFetching}
                isCurrentSearchSearched={isCurrentSearchSearched}
                table={table}
                draft_mode={search.draft_mode || false}
                dirtySearchParams={dirtySearchParams}
                setDirtySearchParams={setDirtySearchParams}
                totalResults={totalResults}
                wasSearched={isCurrentSearchSearched}
                onChangeFilters={onChangeFilters}
                title={search.name || ''}
                appliedFilters={appliedFilters}
                addFilters={addFilters}
                onChangeFilter={onChangeFilter}
                onRemoveFilter={onRemoveFilter}
                metadata={metadata}
            />
            {!wasFirstSearchTriggered && <DataTableInitialState search_type="jobs" />}
            {wasFirstSearchTriggered && (
                <>
                    <DataTable table={table} columns={jobsColumns} isDataFetching={isDataFetching} />
                    <DataTablePagination table={table} totalResults={totalResults} />
                </>
            )}
        </>
    )
}
