import { useImpersonating } from '@/components/hooks/authentication/use-impersonating'
import { useToast } from '@/components/ui/base/use-toast'
import { ConfirmActionDialog } from '@/components/ui/dialog/confirm-action-dialog'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import { getLatestInvoiceConsumption, isCancelationAvailable, isPlanIncomplete, Plan } from '@/schemas/entities/plan'
import { getBillingPortalSessionUrl, deleteInvoiceConsumption as removeConsumption } from '@/services/billing.service'
import { Badge } from '@/shared/ui/base/badge'
import { Button } from '@/shared/ui/base/button'
import { Card } from '@/shared/ui/base/card'
import { formatDateTimeStringToReadableDate } from '@/shared/utils/date-utils'
import { formatNumberWithComma } from '@/shared/utils/number-utils'
import { EraserIcon } from 'lucide-react'
import { useRef } from 'react'
import { Link } from 'react-router-dom'

export function PlanCard({
    plan,
    isCancellationLoading,
    cancelSubscription,
}: {
    plan: Plan
    isCancellationLoading: boolean
    cancelSubscription: (subscription_id: string) => void
}) {
    const { isImpersonating } = useImpersonating()
    const getRemainingDays = (plan: Plan) => {
        if (!plan.current_period_end) return 0
        const currentDate = new Date()
        const currentPeriodEnd = new Date(plan.current_period_end)
        return Math.ceil((currentPeriodEnd.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24))
    }

    const apiCredits = getLatestInvoiceConsumption(plan).api_credits
    const uiCredits = getLatestInvoiceConsumption(plan).ui_credits

    return (
        <Card key={plan.name} className="flex flex-row gap-1 p-2 items-center justify-between">
            <div className="flex flex-col gap-2 items-start justify-start">
                <p className="text-md font-medium ">
                    {plan.name} plan
                    {plan.status != 'active' && (
                        <Badge variant="warning" className="ml-2 text-xs">
                            {plan.status}
                        </Badge>
                    )}
                </p>

                <span className="text-xs text-muted-foreground">
                    {formatDateTimeStringToReadableDate(plan.current_period_start ?? '')} -{' '}
                    {formatDateTimeStringToReadableDate(plan.current_period_end ?? '')}
                    {plan.recurring &&
                        plan.status == 'active' &&
                        !plan.cancel_at_period_end &&
                        ` (renews in ${getRemainingDays(plan)} days)`}
                    {!isCancellationLoading &&
                        plan.status == 'active' &&
                        plan.cancel_at_period_end == true &&
                        ` (active until ${formatDateTimeStringToReadableDate(plan.current_period_end ?? '')} - cancellation scheduled)`}
                </span>

                <div className="flex flex-col gap-2 items-start justify-start">
                    <p className="text-xs">
                        {plan.credits_used_current_period != null && (
                            <span>{formatNumberWithComma(plan.credits_used_current_period)} used of </span>
                        )}
                        {formatNumberWithComma(uiCredits)} company credits {plan.recurring ? '/ month' : ''}
                    </p>
                    <p className="text-xs">
                        {plan.api_credits_used_current_period != null && (
                            <span>{formatNumberWithComma(plan.api_credits_used_current_period)} used of </span>
                        )}
                        <span>
                            {formatNumberWithComma(apiCredits)} API credits {plan.recurring ? '/ month' : ''}
                        </span>
                    </p>
                    {plan.datasets && plan.datasets.length > 0 && (
                        <p className="text-xs">
                            {plan.datasets.length} datasets: {plan.datasets.join(', ')}
                        </p>
                    )}
                </div>
            </div>
            <div className="flex flex-row gap-2 items-center justify-start">
                {plan.name != 'Free' && plan.recurring && plan.status === 'active' && (
                    <Button
                        variant="outline"
                        onClick={async () => {
                            const url = await getBillingPortalSessionUrl()
                            window.open(url)
                        }}
                    >
                        Upgrade plan
                    </Button>
                )}
                {isPlanIncomplete(plan) && (
                    <Button variant="warning" asChild>
                        <Link to={plan.hosted_invoice_url || ''} target="_blank">
                            Complete payment
                        </Link>
                    </Button>
                )}
                {!isCancellationLoading && isCancelationAvailable(plan) && plan.subscription_id && (
                    <Button
                        variant="destructive_outline"
                        onClick={() => cancelSubscription(plan.subscription_id ?? '')}
                    >
                        Cancel plan
                    </Button>
                )}
                {isImpersonating && <AdminActions plan={plan} />}
            </div>
        </Card>
    )
}

function AdminActions({ plan }: { plan: Plan }) {
    const confirmDeleteDialogRef = useRef<OpenDialogHandle>(null)
    const { toast } = useToast()
    return (
        <>
            <Button variant="destructive" size="icon" onClick={() => confirmDeleteDialogRef.current?.open()}>
                <EraserIcon className="w-4 h-4" />
            </Button>
            <ConfirmActionDialog
                ref={confirmDeleteDialogRef}
                title={`Are you sure you want to remove consumption for invoice ${getLatestInvoiceConsumption(plan)?.id}?`}
                description={`This action cannot be undone and will remove ${getLatestInvoiceConsumption(plan)?.used_api_credits} API credits and ${getLatestInvoiceConsumption(plan)?.used_ui_credits} company credits from this invoice.`}
                confirmAction={() => {
                    removeConsumption(getLatestInvoiceConsumption(plan)?.id ?? '')
                        .then(() => {
                            toast({ title: 'Consumption has been removed' })
                            confirmDeleteDialogRef.current?.close()
                        })
                        .catch(() => {
                            toast({ title: 'Failed to remove consumption', variant: 'destructive' })
                            confirmDeleteDialogRef.current?.close()
                        })
                }}
                cancelAction={() => confirmDeleteDialogRef.current?.close()}
                actionText="Remove consumption"
                actionVariant="destructive"
            />
        </>
    )
}
