export const fromCurlToMake = (curl: string) => {
    // Parse the curl command to extract components
    const headers =
        curl.match(/--header\s+"([^"]+)"/g)?.map((h) => {
            const [name, value] = h.match(/--header\s+"([^:]+):\s*([^"]+)"/)?.slice(1) ?? []
            return { name, value }
        }) ?? []

    // Extract URL
    const url = curl.match(/--url\s+"([^"]+)"/)?.[1]

    // Extract data payload
    const dataMatch = curl.match(/-d\s+'({[^']+})'/)?.[1]
    const data = dataMatch ? JSON.parse(dataMatch.replace(/\n\s*/g, '')) : null

    // Construct the output format
    const output = {
        metadata: { version: 1 },
        subflows: [
            {
                flow: [
                    {
                        id: 1,
                        mapper: {
                            ca: null,
                            qs: [],
                            url,
                            data: JSON.stringify(data, null, 2),
                            gzip: true,
                            method: 'post',
                            headers,
                            timeout: null,
                            authPass: null,
                            bodyType: 'raw',
                            contentType: 'application/json',
                            parseResponse: true,
                            followRedirect: true,
                            rejectUnauthorized: true,
                            serializeUrl: true,
                            shareCookies: false,
                            followAllRedirects: true,
                            useQuerystring: false,
                            useMtls: false,
                        },
                        module: 'http:ActionSendData',
                        version: 3,
                        metadata: {
                            restore: {
                                expect: {
                                    qs: { mode: 'chose' },
                                    method: { mode: 'chose', label: null },
                                    headers: { mode: 'chose', items: [] },
                                    bodyType: { label: 'raw' },
                                    contentType: { label: 'JSON (application/json)' },
                                },
                            },
                            designer: { x: 0, y: 0, name: 'Theirstack API Request' },
                            parameters: [],
                        },
                        parameters: {
                            useNewZLibDeCompress: true,
                        },
                    },
                ],
            },
        ],
    }

    return JSON.stringify(output)
}
