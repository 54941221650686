import { getHTTPHeaders, getHTTPHeadersImpersonating } from '@/lib/authentication/authentication-http-headers'
import { constructURL, sourceSessionParamsObject } from '@/lib/http-utils'
import { User, userSchema, UserWithTeam, userWithTeamSchema } from '@/schemas/entities/user'
import { z } from 'zod'

const RESOURCE_URL = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/users'
const RESOURCE_URL_V0 = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/v0/users'

export async function getMyProfile(): Promise<UserWithTeam> {
    const res = await fetch(RESOURCE_URL_V0 + '/me', { method: 'GET', headers: getHTTPHeadersImpersonating() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return userWithTeamSchema.parse(await res.json())
}

export async function getAllActiveUsers(email_substring: string): Promise<User[]> {
    const url = constructURL(`${RESOURCE_URL}`, {
        ...sourceSessionParamsObject(),
        is_active: true,
        limit: 50,
        email_substring: email_substring,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeaders() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return z.array(userSchema).parse(await res.json())
}

export type SignupResponse = 'created' | 'email_already_exist' | 'email_not_allowed'

export async function signup(first_name: string, last_name: string, email: string): Promise<SignupResponse> {
    const body = new URLSearchParams({
        username: email,
        password: '',
        first_name: first_name,
        last_name: last_name,
    })
    const url = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + '/signup'
    const res = await fetch(url, {
        method: 'POST',
        headers: { Accept: 'application/x-www-form-urlencoded', 'Content-Type': 'application/x-www-form-urlencoded' },
        body: body.toString(),
    })
    return res.status === 201 ? 'created' : res.status === 400 ? 'email_not_allowed' : 'email_already_exist'
}

export async function getCheckoutSessionUrl(plan_id: string): Promise<string> {
    const url = constructURL(`${RESOURCE_URL}/me/get_checkout_session_url`, {
        ...sourceSessionParamsObject(),
        price_id: plan_id,
    })
    const res = await fetch(url, { method: 'GET', headers: getHTTPHeaders() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return (await res.text()).replaceAll('"', '')
}

export async function deleteUser(user_id: number): Promise<boolean> {
    const url = constructURL(`${RESOURCE_URL}/${user_id}`, { ...sourceSessionParamsObject() })
    const res = await fetch(url, { method: 'DELETE', headers: getHTTPHeaders() })
    if (!res.ok) throw new Error('Failed to fetch data')
    return res.status === 200
}
