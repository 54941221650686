import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/base/form'
import { Textarea } from '@/components/ui/base/textarea'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import { MultiSelectTechnologies } from '@/components/ui/form/multi-select-technologies/multi-select-technologies'
import { fromStringToArray } from '@/components/ui/search-filter/search-filter-form-array-strings'
import { CompaniesSearchParams } from '@/schemas/request/companies-search-params.schema'
import { EVENT_ENRICH_MODAL_VIEW, EVENT_ENRICH_RUN_CLICK, sendEvent } from '@/services/tracking.service'
import { Button } from '@/shared/ui/base/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/shared/ui/base/dialog'
import { zodResolver } from '@hookform/resolvers/zod'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { get_search_url } from './saved-searches/recent-searches-card'

const FormSchema = z.object({
    websiteList: z.string().optional(),
    companyNames: z.string().optional(),
})

export const EnrichDialog = forwardRef<OpenDialogHandle>((_props, ref) => {
    const [show, setShow] = useState(false)
    const [companiesInputError, setGlobalError] = useState<string | null>(null)
    const [technologiesInputError, setTechnologiesError] = useState<string | null>(null)
    const [selectedTechnologies, setSelectedTechnologies] = useState<string[]>([])
    useImperativeHandle(ref, () => ({
        open: () => {
            setShow(true)
            sendEvent(EVENT_ENRICH_MODAL_VIEW)
        },
        close: () => setShow(false),
    }))

    const form = useForm({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            websiteList: '',
            companyNames: '',
        },
    })

    const onSubmit = (data: z.infer<typeof FormSchema>) => {
        if (!data.websiteList?.trim() && !data.companyNames?.trim()) {
            setGlobalError('At least one website or company name must be provided')
            return
        }
        if (selectedTechnologies.length === 0) {
            setTechnologiesError('At least one technology must be selected')
            return
        }
        setGlobalError(null)
        setTechnologiesError(null)
        sendEvent(EVENT_ENRICH_RUN_CLICK)
        const search: CompaniesSearchParams = {
            company_name_or: fromStringToArray(data.companyNames || ''),
            company_domain_or: fromStringToArray(data.websiteList || ''),
            expand_technology_slugs: selectedTechnologies,
        }
        const link = get_search_url('companies', search)
        window.open(link, '_blank')
    }

    const handleInputChange = () => {
        if (companiesInputError) {
            setGlobalError(null)
        }
    }

    return (
        <Dialog open={show} onOpenChange={(open) => setShow(open)}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="text-xl">Tech stack enrichment</DialogTitle>
                </DialogHeader>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-6">
                        <div className="flex flex-col gap-2">
                            <h2 className="text-base font-medium">1. Select companies</h2>
                            <p className="text-sm text-muted-foreground">
                                Enter a list of company websites and/or names separated by new lines.
                            </p>
                            <div className="grid grid-cols-2 gap-2">
                                <FormField
                                    control={form.control}
                                    name="websiteList"
                                    render={({ field }) => (
                                        <FormItem>
                                            <label htmlFor="website-list" className="text-sm">
                                                Websites
                                            </label>
                                            <FormControl>
                                                <Textarea
                                                    id="website-list"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        handleInputChange()
                                                    }}
                                                    className="border border-gray-300 rounded-md p-2"
                                                    placeholder="google.com&#10;facebook.com&#10;twitter.com&#10;linkedin.com"
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="companyNames"
                                    render={({ field }) => (
                                        <FormItem>
                                            <label htmlFor="company-names" className="text-sm">
                                                Company names
                                            </label>
                                            <FormControl>
                                                <Textarea
                                                    id="company-names"
                                                    {...field}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        handleInputChange()
                                                    }}
                                                    className="border border-gray-300 rounded-md p-2"
                                                    placeholder="Google&#10;Facebook&#10;Twitter&#10;LinkedIn"
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            {companiesInputError && <p className="text-red-500 text-sm">{companiesInputError}</p>}
                        </div>
                        <div className="flex flex-col gap-2">
                            <h2 className="text-base font-medium">2. Select technologies</h2>
                            <p className="text-sm text-muted-foreground">
                                See if any of the companies you selected use any of the following technologies.
                            </p>
                            <MultiSelectTechnologies value={[]} onChange={setSelectedTechnologies} />
                            {technologiesInputError && <p className="text-red-500 text-sm">{technologiesInputError}</p>}
                        </div>
                        <DialogFooter>
                            <Button variant="outline" type="button" onClick={() => setShow(false)}>
                                Cancel
                            </Button>
                            <Button variant="default" type="submit">
                                Enrich
                            </Button>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
})
