import { useTechnology } from '@/components/hooks/use-technology'
import CompanyCard from '@/components/ui/company/company-card'
import CountryCard from '@/components/ui/country/country-card'
import { isChecked } from '@/components/ui/table/columns/column-utils'
import { DataTableColumnHeader } from '@/components/ui/table/data-table-column-header'
import { DataTableCompanyColumnHeader } from '@/components/ui/table/data-table-company-column-header'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { CompaniesSearchParams } from '@/schemas/request/companies-search-params.schema'
import { Checkbox } from '@/shared/ui/base/checkbox'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/shared/ui/base/hover-card'
import { formatCompactNumber, formatNumberWithComma } from '@/shared/utils/number-utils'
import { ColumnDef } from '@tanstack/react-table'
import HistoricalJobsColumn from './historical-jobs-column'
import JobMatchingFiltersColumn from './jobs-matching-filters-column'
import RevealCompanyButton from './reveal-company-button'
import { HoverTechnologyCard } from './technology-column/hover-technology-card'
import TechnologyCard from './technology-column/technology-card'
import TechnologyColumn from './technology-column/technology-column'

export const TECHNOLOGY_COLUMN_PREFIX = 'technology-'

export const hiddenCompanyColumns = { 'company.revenue_usd': true, num_jobs_found: false }

export const companyColumns: ColumnDef<FullCompany>[] = [
    {
        id: 'select',
        header: ({ table }) => {
            return (
                <Checkbox
                    checked={isChecked(table)}
                    onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                    aria-label="Select all"
                    className="translate-y-[2px] ml-1.5"
                />
            )
        },
        cell: ({ row }) => (
            <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                className="translate-y-[2px] ml-1.5"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        id: 'reveal',
        accessorKey: 'reveal',
        meta: {
            name: () => 'Reveal',
        },
        header: ({ column, table }) => (
            <DataTableCompanyColumnHeader
                column={column}
                title="Company"
                table={table}
                fromRowsToCompanies={(rows) => rows.map((row) => row.original)}
            />
        ),
        cell: ({ row, table }) => {
            return (
                <RevealCompanyButton
                    company={row.original}
                    updateCompany={(company: FullCompany) => {
                        if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company)
                    }}
                />
            )
        },
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: 'name',
        meta: {
            name: () => 'Company',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Company" />,
        cell: ({ row }) => {
            return <>{row.original && <CompanyCard company={row.original} />}</>
        },
        enableSorting: true,
        enableHiding: true,
    },

    {
        id: 'company.country',
        accessorKey: 'company_object',
        meta: {
            name: () => 'HQ Country',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="HQ Country" />,
        cell: ({ row }) => {
            return <>{row.original.country_code && <CountryCard countryIso={row.original.country_code} />}</>
        },
        enableSorting: false,
        enableHiding: true,
    },

    {
        id: 'company.industry',
        accessorKey: 'company_object',
        meta: {
            name: () => 'Industry',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Industry" />,
        cell: ({ row }) => {
            return <p className="max-w-56 truncate font-normal">{row.original.industry}</p>
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: 'employee_count',
        accessorKey: 'company_object',
        meta: {
            name: () => 'Employees',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Employees" />,
        cell: ({ row }) => {
            return (
                <p className="max-w-20 truncate font-normal">
                    {row.original.employee_count ? formatNumberWithComma(row.original.employee_count) : ''}
                </p>
            )
        },
        enableHiding: true,
    },
    {
        id: 'company.revenue_usd',
        accessorKey: 'company_object',
        meta: {
            name: () => 'Revenue',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Revenue" />,
        cell: ({ row }) => {
            return (
                <p className="max-w-20 truncate font-normal">
                    {row.original?.annual_revenue_usd ? '$' + formatCompactNumber(row.original.annual_revenue_usd) : ''}
                </p>
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        id: 'company_city',
        accessorKey: 'company_object',
        meta: {
            name: () => 'City',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="City" />,
        cell: ({ row }) => {
            return <p>{row.original?.city}</p>
        },
        enableSorting: false,
        enableHiding: true,
    },

    {
        accessorKey: 'num_jobs_found',
        meta: {
            name: () => 'Jobs matching filters',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Jobs matching filters" />,
        cell: ({ row, table }) => {
            return (
                <>
                    {table.options?.meta?.getCompanySearchParams && (
                        <JobMatchingFiltersColumn
                            company={row.original}
                            searchPams={table.options.meta.getCompanySearchParams()}
                            updateCompany={(company: FullCompany) => {
                                if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company)
                            }}
                        />
                    )}
                </>
            )
        },
        enableSorting: true,
        enableHiding: true,
    },
    {
        id: 'technology_count',
        accessorKey: 'company_object',
        meta: {
            name: () => 'Technologies',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Technologies" />,
        cell: ({ row, table }) => {
            return (
                <TechnologyColumn
                    company={row.original}
                    updateCompany={(company: FullCompany) => {
                        if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company)
                    }}
                />
            )
        },
        enableSorting: false,
        enableHiding: true,
    },
    {
        accessorKey: 'num_jobs',
        meta: {
            name: () => 'Historical Jobs',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Historical Jobs" />,
        cell: ({ row, table }) => {
            return (
                <HistoricalJobsColumn
                    company={row.original}
                    updateCompany={(company: FullCompany) => {
                        if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company)
                    }}
                />
            )
        },
        enableSorting: true,
        enableHiding: true,
    },
]

export const getNewDynamicColumns = (ids: string[]): ColumnDef<FullCompany>[] => {
    return ids.map((id) => getNewDynamicColumn(id))
}

const getNewDynamicColumn = (id: string): ColumnDef<FullCompany> => {
    return {
        id: id,
        accessorKey: id,
        meta: {
            name: function ColumnName() {
                const { technology } = useTechnology(id.replace(TECHNOLOGY_COLUMN_PREFIX, ''))
                return technology?.name
            },
        },
        header: function Header({ column }) {
            const { technology } = useTechnology(id.replace(TECHNOLOGY_COLUMN_PREFIX, ''))
            return <DataTableColumnHeader className="capitalize" column={column} title={technology?.name} />
        },
        cell: ({ row, table }) => {
            const techdetails = row.original.technologies_found.find(
                (technology) => TECHNOLOGY_COLUMN_PREFIX + technology.technology.slug === id
            )
            if (!techdetails) return <p className="text-muted-foreground text-sm">Not used</p>

            return (
                <HoverCard key={techdetails.technology.slug} openDelay={50} closeDelay={0}>
                    <HoverCardTrigger>
                        <TechnologyCard techdetails={techdetails} />
                    </HoverCardTrigger>
                    <HoverCardContent className="w-80">
                        <HoverTechnologyCard
                            company={row.original}
                            techdetails={techdetails}
                            updateCompany={(company: FullCompany) => {
                                if (table.options.meta?.updateRow) table.options.meta?.updateRow(row.index, company)
                            }}
                        />
                    </HoverCardContent>
                </HoverCard>
            )
        },
        enableSorting: false,
        enableHiding: true,
    }
}

export const updateSearchParamsWithDynamicColumns = (searchParams: CompaniesSearchParams, ids: string[]) => {
    const expand_technology_slugs = ids
        .filter((id) => id.includes(TECHNOLOGY_COLUMN_PREFIX))
        .map((id) => id.replace(TECHNOLOGY_COLUMN_PREFIX, ''))
    return { ...searchParams, expand_technology_slugs }
}
