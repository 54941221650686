import { z } from 'zod'
import { teamSchema } from './team'

export const userSchema = z.object({
    id: z.number(),
    first_name: z.string().nullable().optional(),
    last_name: z.string().nullable().optional(),
    email: z.string(),
    is_active: z.boolean(),
    is_superuser: z.boolean(),
    token: z.string(),
})

export type User = z.infer<typeof userSchema>

export const userWithTeamSchema = userSchema.extend({
    team: teamSchema,
})

export type UserWithTeam = z.infer<typeof userWithTeamSchema>
