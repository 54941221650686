import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/base/form'
import { Input } from '@/components/ui/base/input'
import { Textarea } from '@/components/ui/base/textarea'
import { isURL } from '@/lib/http-utils'
import { fetchTechnologyDetails } from '@/services/technology-explorer.service'
import { Button } from '@/shared/ui/base/button'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'

const FormSchema = z.object({
    url: z.string().refine((value) => isURL(value), { message: 'Invalid URL' }),
    name: z.string().min(1, { message: 'Name is required' }),
    logo: z.string().url({ message: 'Invalid Logo URL' }).min(1, { message: 'Logo URL is required' }),
    oneLiner: z.string().min(1, { message: 'One-liner is required' }),
    longDescription: z.string().min(1, { message: 'Long description is required' }),
})

export const NewTechnologyForm = () => {
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            url: '',
            name: '',
            logo: '',
            oneLiner: '',
            longDescription: '',
        },
    })

    const url = useWatch({ control: form.control, name: 'url' })
    const logo = useWatch({ control: form.control, name: 'logo' })

    useEffect(() => {
        if (url) {
            fetchTechnologyDetails(url).then((details) => {
                form.setValue('name', details.name)
                form.setValue('logo', details.logo)
                form.setValue('oneLiner', details.oneLiner)
                form.setValue('longDescription', details.longDescription)
            })
        }
    }, [url, form])

    const onSubmit = (data: z.infer<typeof FormSchema>) => {
        console.log('Technology Details:', data)
    }

    return (
        <div className="w-2/3 mx-auto">
            <div className="grid grid-cols-1 gap-4">
                <h1 className="text-2xl font-semibold text-center">Add Technology</h1>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4">
                        <FormField
                            control={form.control}
                            name="url"
                            render={({ field }) => (
                                <FormItem>
                                    <label htmlFor="technology-url" className="text-lg font-medium">
                                        Website URL
                                    </label>
                                    <FormControl>
                                        <Input
                                            type="url"
                                            id="technology-url"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <label htmlFor="technology-name" className="text-lg font-medium">
                                        Name
                                    </label>
                                    <FormControl>
                                        <Input
                                            type="text"
                                            id="technology-name"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="logo"
                            render={({ field }) => (
                                <FormItem>
                                    <label htmlFor="technology-logo" className="text-lg font-medium">
                                        Logo URL
                                    </label>
                                    <div className="flex flex-row gap-2 justify-between">
                                        <div className="flex-grow">
                                            <FormControl>
                                                <Input
                                                    type="url"
                                                    id="technology-logo"
                                                    {...field}
                                                    className="border border-gray-300 rounded-md p-2"
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>
                                        {logo && (
                                            <div className="">
                                                <img
                                                    src={logo}
                                                    alt="Technology Logo"
                                                    className="w-16 h-16 object-cover border border-gray-300"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="oneLiner"
                            render={({ field }) => (
                                <FormItem>
                                    <label htmlFor="technology-one-liner" className="text-lg font-medium">
                                        One-liner
                                    </label>
                                    <FormControl>
                                        <Textarea
                                            id="technology-one-liner"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="longDescription"
                            render={({ field }) => (
                                <FormItem>
                                    <label htmlFor="technology-long-description" className="text-lg font-medium">
                                        Description
                                    </label>
                                    <FormControl>
                                        <Textarea
                                            id="technology-long-description"
                                            {...field}
                                            className="border border-gray-300 rounded-md p-2"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit" className="bg-primary text-white rounded-md p-2">
                            Submit
                        </Button>
                    </form>
                </Form>
            </div>
        </div>
    )
}
