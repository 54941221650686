export const fetchTechnologyDetails = async (url: string) => {
    const response = await fetch(url)
    const html = await response.text()
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    const name = doc.querySelector('title')?.innerText || 'No title found'
    const logoElement = doc.querySelector('link[rel="icon"]')
    const logo = logoElement instanceof HTMLLinkElement ? logoElement.href : 'No logo found'
    const oneLinerElement = doc.querySelector('meta[name="description"]')
    const oneLiner = oneLinerElement instanceof HTMLMetaElement ? oneLinerElement.content : 'No description found'
    const longDescriptionElement = doc.querySelector('meta[name="description"]')
    const longDescription =
        longDescriptionElement instanceof HTMLMetaElement ? longDescriptionElement.content : 'No long description found'

    return {
        name,
        logo,
        oneLiner,
        longDescription,
    }
}
