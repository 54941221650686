import { useErrorNotification } from '@/components/hooks/toast/use-error-notification'
import { Switch } from '@/components/ui/base/switch'
import TallyForm from '@/components/ui/tally.form'
import { UserWithTeam } from '@/schemas/entities/user'
import { cancelSubscription, getBillingPortalSessionUrl } from '@/services/billing.service'
import { getMyProfile } from '@/services/user.service'
import { Button } from '@/shared/ui/base/button'
import { Dialog, DialogContent, DialogTitle } from '@/shared/ui/base/dialog'
import { Separator } from '@/shared/ui/base/separator'
import { WithTooltip } from '@/shared/ui/base/tooltip'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { LucideHelpCircle } from 'lucide-react'
import { useEffect, useState } from 'react'
import CreditsUsage from './components/credits-usage'
import { PlanCard } from './components/plan-card'

export const REVEAL_COMPANIES_AUTOMATICALLY = 'ts-reveal-companies-automatically'

export function isRevealCompaniesAutomaticallyEnabled(): boolean {
    if (localStorage.getItem(REVEAL_COMPANIES_AUTOMATICALLY)) {
        return localStorage.getItem(REVEAL_COMPANIES_AUTOMATICALLY) === 'true'
    } else {
        return false
    }
}

export function setIsRevealCompaniesAutomaticallyEnabled(enabled: boolean) {
    localStorage.setItem(REVEAL_COMPANIES_AUTOMATICALLY, enabled ? 'true' : 'false')
}

function BillingPage() {
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    })

    useEffect(() => {
        document.title = 'Billing · Settings · TheirStack'
    }, [])
    const [showCancelDialog, setShowCancelDialog] = useState(false)
    const [subscriptionIdToCancel, setSubscriptionIdToCancel] = useState<string | null>(null)
    const [isCancellationLoading, setIsCancellationLoading] = useState(false)
    const queryClient = useQueryClient()
    const { showErrorNotification } = useErrorNotification({ isError: false })

    const onConfirmCancel = (subscription_id: string) => {
        setIsCancellationLoading(true)
        cancelSubscription(subscription_id)
            .then(() => {
                setSubscriptionIdToCancel(null)
                queryClient
                    .invalidateQueries({ queryKey: ['me'] })
                    .then(() => {
                        setIsCancellationLoading(false)
                    })
                    .catch(() => {
                        setIsCancellationLoading(false)
                        showErrorNotification()
                    })
            })
            .catch(() => {
                setSubscriptionIdToCancel(null)
                setIsCancellationLoading(false)
                showErrorNotification()
            })
    }

    const showConfirmCancelDialog = (subscription_id: string) => {
        setShowCancelDialog(true)
        setSubscriptionIdToCancel(subscription_id)
    }

    return (
        <div className="space-y-8">
            <div>
                <h3 className="text-lg font-medium">Billing</h3>
                <p className="text-sm text-muted-foreground">
                    Manage your plans, credits, invoices and payment method.
                </p>
            </div>
            <Separator />
            {!me && <p>Loading...</p>}

            {me && me.team && (
                <>
                    <CreditsUsage me={me} />
                    <div>
                        <h2 className="text-lg font-medium mb-4">Plans</h2>
                        <div className="flex flex-col gap-2">
                            {me.team.plans
                                .filter(
                                    (plan) =>
                                        plan.status in ['active', 'incomplete'] ||
                                        plan.invoices.some(
                                            (p) =>
                                                p.used_api_credits < p.api_credits || p.used_ui_credits < p.ui_credits
                                        )
                                )
                                ?.sort((a, b) => a.credits - b.credits)
                                .map((plan) => (
                                    <PlanCard
                                        key={plan.name + plan.credits + plan.created_at}
                                        plan={plan}
                                        isCancellationLoading={isCancellationLoading}
                                        cancelSubscription={showConfirmCancelDialog}
                                    />
                                ))}
                        </div>
                        <div className="flex flex-col gap-2 items-start mt-4">
                            <Button
                                variant="outline"
                                onClick={async () => {
                                    const url = await getBillingPortalSessionUrl()
                                    window.open(url)
                                }}
                            >
                                Manage Billing
                            </Button>

                            <p className="text-sm text-muted-foreground">
                                Click on "Manage Billing" to update your payment method, view your billing history, or
                                download invoices.
                            </p>
                        </div>
                    </div>
                    {showCancelDialog && (
                        <Dialog open={true} onOpenChange={setShowCancelDialog}>
                            <DialogContent
                                className="overflow-scroll max-h-full maw-w-full sm:max-w-[900px]"
                                aria-describedby={undefined}
                            >
                                <VisuallyHidden.Root asChild>
                                    <DialogTitle>Cancel subscription</DialogTitle>
                                </VisuallyHidden.Root>
                                <TallyForm
                                    src={`https://tally.so/embed/w42Jqo?hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${encodeURIComponent(me.email)}`}
                                    onFormSubmitted={() => {
                                        if (subscriptionIdToCancel) {
                                            onConfirmCancel(subscriptionIdToCancel)
                                        }
                                    }}
                                />
                            </DialogContent>
                        </Dialog>
                    )}

                    <div>
                        <h2 className="text-lg font-medium mb-4">Records visibility</h2>
                        <div className="flex space-x-2 w-80 items-center">
                            <p className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Reveal companies automatically
                            </p>
                            <WithTooltip
                                title="All companies and jobs will visible by default when searching, each search will consume credits"
                                side="right"
                            >
                                <LucideHelpCircle className="h-4 w-4 text-muted-foreground" />
                            </WithTooltip>
                            <Switch
                                defaultChecked={isRevealCompaniesAutomaticallyEnabled()}
                                onCheckedChange={(checked) => setIsRevealCompaniesAutomaticallyEnabled(checked)}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default BillingPage
