import { z } from 'zod'
import { companyObjectSchema } from './company-object-base'

export const jobsSchema = companyObjectSchema.extend({
    id: z.number(),
    job_title: z.string(),
    url: z.string(),
    source_url: z.string().nullable().optional(),
    final_url: z.string().nullable().optional(),
    has_blurred_data: z.boolean().nullable().optional(),

    location: z.string().nullable().optional(),
    remote: z.boolean().nullable().optional(),
    easy_apply: z.boolean().nullable().optional(),
    salary_string: z.string().nullable().optional(),
    min_anual_salary: z.number().nullable().optional(),
    min_anual_salary_usd: z.number().nullable().optional(),
    max_anual_salary: z.number().nullable().optional(),
    max_anual_salary_usd: z.number().nullable().optional(),
    description: z.string().nullable().optional(),

    /* Location */
    countries: z.array(z.string()).nullable().optional(),
    country: z.string().nullable().optional(),
    country_code: z.string().nullable().optional(),
    country_codes: z.array(z.string()).nullable().optional(),
    cities: z.array(z.string()).nullable().optional(),
    continents: z.array(z.string()).nullable().optional(),
    date_posted: z.string(),
    discovered_at: z.string(),
    seniorty_level: z.string().nullable().optional(),

    /* Company */
    company: z.string().nullable().optional(),
    companies_urls: z.array(z.string()).nullable().optional(),
    funding_stages: z.array(z.string()).nullable().optional(),

    technology_names: z.array(z.string()).nullable().optional(),

    matching_phrases: z.array(z.string()).nullable().optional(),
    matching_words: z.array(z.string()).nullable().optional(),

    manager_roles: z.array(z.string()).nullable().optional(),

    hiring_team: z
        .array(
            z.object({
                first_name: z.string().nullable().optional(),
                full_name: z.string().nullable().optional(),
                linkedin_url: z.string().nullable().optional(),
                image_url: z.string().nullable().optional(),
                thumbnail_url: z.string().nullable().optional(),
                role: z.string().nullable().optional(),
            })
        )
        .nullable()
        .optional(),
})

export type Job = z.infer<typeof jobsSchema>
