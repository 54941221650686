import { getHTTPHeaders, getHTTPHeadersWithTokenPlaceholder } from '@/lib/authentication/authentication-http-headers'
import { constructURL, fetchToCurl, HTTP_STATUS_CODES, sourceSessionParamsObject } from '@/lib/http-utils'
import { isRevealCompaniesAutomaticallyEnabled } from '@/pages/settings/billing/billing.page'
import { Pagination } from '@/schemas/entities/pagination.schema'
import { JobsSearchParams } from '@/schemas/request/jobs-search-params.schema'
import { JobCountResponse, jobsCountResponseSchema } from '@/schemas/responses/jobs-count-response.schema'
import { JobResponse, jobsResponseSchema } from '@/schemas/responses/jobs-response.schema'
import { PaginationState } from '@tanstack/react-table'

const RESOURCE_URL = '/v1/jobs/'

export function getJobsFromSearchParamsBody(
    params: JobsSearchParams,
    pagination: Pagination,
    include_total_results: boolean
): JobsSearchParams {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    newParams.offset = pagination.offset
    newParams.include_total_results = include_total_results
    if (newParams.blur_company_data === undefined) {
        newParams.blur_company_data = isRevealCompaniesAutomaticallyEnabled() ? false : true
    }
    return newParams
}

export async function getJobsFromSearchParams(
    params: JobsSearchParams,
    pagination: Pagination,
    include_total_results: boolean
): Promise<JobResponse> {
    const url = constructURL(
        import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + 'search',
        sourceSessionParamsObject()
    )
    const options = {
        method: 'POST',
        headers: getHTTPHeaders(),
        body: JSON.stringify(getJobsFromSearchParamsBody(params, pagination, include_total_results)),
    }
    const res = await fetch(url, options)
    if (!res.ok && res.status !== HTTP_STATUS_CODES.PAYMENT_REQUIRED) {
        throw new Error('Failed to fetch data')
    }
    return jobsResponseSchema.parse(await res.json())
}

export async function exportJobsToWebhook(
    webhook_url: string,
    limit: number,
    params: JobsSearchParams,
    pagination: Pagination
): Promise<void> {
    const url = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + 'export'
    const options = {
        method: 'POST',
        headers: getHTTPHeaders(),
        body: JSON.stringify({
            webhook_url,
            limit,
            filters: getJobsFromSearchParamsBody(params, pagination, false),
        }),
    }
    await fetch(url, options)
}

export function getJobsCURL(params: JobsSearchParams, pagination: Pagination, transform: boolean = true): string {
    const url = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + 'search'
    const body = getJobsFromSearchParamsBody(params, pagination, false)
    body.blur_company_data = false
    const options = {
        method: 'POST',
        headers: getHTTPHeadersWithTokenPlaceholder(),
        body: JSON.stringify(transform ? body : params),
    }
    return fetchToCurl(url, options)
}

export async function getJobCountFromSearchParams(
    params: JobsSearchParams,
    pagination: PaginationState
): Promise<JobCountResponse> {
    const newParams = { ...params }
    newParams.page = pagination.pageIndex
    newParams.limit = pagination.pageSize
    const url = constructURL(
        import.meta.env.VITE_THEIRSTACK_API_SERVER_URL + RESOURCE_URL + 'search/counts_within_limit',
        sourceSessionParamsObject()
    )
    const res = await fetch(url, { method: 'POST', headers: getHTTPHeaders(), body: JSON.stringify(newParams) })

    if (!res.ok) {
        throw new Error('Failed to fetch data')
    }

    return jobsCountResponseSchema.parse(await res.json())
}
