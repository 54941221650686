import { getTechnologiesBySlugs } from '@/services/technology.service'
import { Technology } from '@/shared/schemas/entity/technology.schema'
import { useQuery } from '@tanstack/react-query'

export function useTechnology(slug: string) {
    const { data = [] } = useQuery<Technology[]>({
        queryKey: ['technologies', 'slug', '0', [slug]],
        queryFn: () => {
            return getTechnologiesBySlugs([slug])
        },
        staleTime: Infinity,
    })
    return { technology: data?.[0] }
}
