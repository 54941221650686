import { z } from 'zod'
import { planSchema } from './plan'

export const teamSchema = z.object({
    id: z.number(),
    name: z.string(),
    stripe_customer_id: z.string(),
    plans: z.array(planSchema),
    credits_used_current_period: z.number(),
    credits_left_current_period: z.number(),
    current_period_start: z.string(),
    current_period_end: z.string(),
    credits: z.number(),
    api_credits: z.number(),
    api_credits_used_current_period: z.number(),
    api_credits_left_current_period: z.number(),
})
