import { EVENT_API_CURL_MODAL_COPY, EVENT_API_CURL_MODAL_VIEW, sendEvent } from '@/services/tracking.service'
import { Button } from '@/shared/ui/base/button'
import { Braces, Copy, ExternalLink } from 'lucide-react'
import { WithTooltip } from '../../../../../shared/ui/base/tooltip'
import { Sheet, SheetContent, SheetTitle, SheetTrigger } from '../../../base/sheet'

import { useImpersonating } from '@/components/hooks/authentication/use-impersonating'
import { fromCurlToMake } from '@/lib/third-party-scripts/make'
import { fromCurlToN8N } from '@/lib/third-party-scripts/n8n'
import { UserWithTeam } from '@/schemas/entities/user'
import { getMyProfile } from '@/services/user.service'
import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@/shared/ui/base/dialog'
import { Separator } from '@/shared/ui/base/separator'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { useToast } from '../../../base/use-toast'
import { AdminCurlActions } from './admin-curl-actions'

export default function APICurlButton({
    curl,
    className,
    variant = 'ghost',
    buttonTitle = 'API',
}: {
    curl: string
    className?: string
    variant?: 'ghost' | 'outline'
    buttonTitle?: string
}) {
    const { isImpersonating } = useImpersonating()
    const { toast } = useToast()
    const [isMakeDialogOpen, setIsMakeDialogOpen] = useState(false)
    const [isN8NDialogOpen, setIsN8NDialogOpen] = useState(false)
    const { data: me = null } = useQuery<UserWithTeam>({
        queryKey: ['me'],
        queryFn: getMyProfile,
        staleTime: HOUR_IN_MS,
    })

    const getCurlWithToken = (curl: string) => {
        let code = curl
        if (me) {
            code = code.replace('<api_key>', me.token)
        }
        return code
    }

    const copyCurlToClipboard = (_curl: string, title: string) => {
        navigator.clipboard.writeText(_curl)
        toast({ title })
    }

    const getEndpointFromCurl = (curl: string) => {
        const baseUrl = import.meta.env.VITE_THEIRSTACK_API_SERVER_URL
        if (curl.includes(baseUrl)) {
            return curl.split(baseUrl)[1].split('"')[0]
        }
        return ''
    }

    return (
        <div className={`${className}`}>
            <Sheet>
                <WithTooltip title="Pull this list with our API">
                    <SheetTrigger asChild>
                        <Button
                            variant={variant}
                            size="sm"
                            className="text-sm font-normal"
                            onClick={() => {
                                sendEvent(EVENT_API_CURL_MODAL_VIEW)
                            }}
                        >
                            <Braces className="h-4 w-4 md:mr-2" />
                            <p className="hidden lg:inline">{buttonTitle}</p>
                        </Button>
                    </SheetTrigger>
                </WithTooltip>
                <SheetContent className="w-full md:w-2/3 lg:w-3/5 xl:w-2/5 overflow-y-auto">
                    <SheetTitle>Pull this list with our API</SheetTitle>
                    <div className="mt-4">
                        <div className="flex gap-2 mb-2 justify-end flex-wrap">
                            <Button
                                size="xs"
                                variant="outline"
                                onClick={() => {
                                    copyCurlToClipboard(
                                        fromCurlToMake(getCurlWithToken(curl)),
                                        'Copied MAKE HTTP Module to clipboard'
                                    )
                                    setIsMakeDialogOpen(true)
                                }}
                            >
                                <img src="/tools/make-logo.png" className="h-4 w-4 mr-2" />
                                Make
                            </Button>
                            <Button
                                size="xs"
                                variant="outline"
                                onClick={() => {
                                    copyCurlToClipboard(
                                        fromCurlToN8N(getCurlWithToken(curl)),
                                        'Copied N8N HTTP Request to clipboard'
                                    )
                                    setIsN8NDialogOpen(true)
                                }}
                            >
                                <img src="/tools/n8n-logo.png" className="h-4 w-4 mr-2" />
                                N8N
                            </Button>
                            {(me && isImpersonating) || me?.is_superuser ? (
                                <AdminCurlActions
                                    curl={curl}
                                    copyCurlToClipboard={copyCurlToClipboard}
                                    me={me}
                                    isImpersonating={isImpersonating}
                                />
                            ) : (
                                <Button
                                    size="xs"
                                    variant="outline"
                                    onClick={() => copyCurlToClipboard(getCurlWithToken(curl), 'Copied to clipboard')}
                                >
                                    <Copy className="mr-2 h-4 w-4" />
                                    cURL
                                </Button>
                            )}
                        </div>
                        <div className="flex flex-col bg-[#1E1E1E] rounded-md">
                            <div className="flex justify-between">
                                <p className="text-sm text-white p-2">
                                    <span className="text-green-500 mr-2">POST</span> {getEndpointFromCurl(curl)}
                                </p>
                            </div>
                            <Separator className="bg-white" />
                            <SyntaxHighlighter
                                className="max-h-[70vh] overflow-scroll m-0"
                                language="bash"
                                style={vscDarkPlus}
                                showLineNumbers={true}
                            >
                                {curl}
                            </SyntaxHighlighter>
                        </div>

                        <div className="flex gap-2 mt-2 flex-wrap">
                            <Link to={`/settings/api-key`} target="_blank">
                                <Button size="xs" variant="outline">
                                    <ExternalLink className="mr-2 h-4 w-4" />
                                    API Key
                                </Button>
                            </Link>
                            <Button
                                size="xs"
                                variant={'outline'}
                                onClick={() => {
                                    sendEvent(EVENT_API_CURL_MODAL_COPY)
                                    window.open('https://api.theirstack.com', '_blank')
                                }}
                            >
                                <ExternalLink className="mr-2 h-4 w-4" />
                                API Docs
                            </Button>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>

            <Dialog open={isMakeDialogOpen} onOpenChange={setIsMakeDialogOpen}>
                <DialogContent
                    className="overflow-scroll max-h-full maw-w-full sm:max-w-[900px]"
                    aria-describedby={undefined}
                >
                    <DialogTitle>Make HTTP Module</DialogTitle>
                    <DialogDescription>
                        <p className="text-sm">
                            The Make HTTP module has been copied to your clipboard. You can now paste (CTRL+V or CMD+V)
                            it into your Make.com scenario.
                        </p>
                        <img src="/paste-http-module-make.gif" className="w-2/3 mx-auto mt-6 rounded-md" />
                    </DialogDescription>
                </DialogContent>
            </Dialog>
            <Dialog open={isN8NDialogOpen} onOpenChange={setIsN8NDialogOpen}>
                <DialogContent
                    className="overflow-scroll max-h-full maw-w-full sm:max-w-[900px]"
                    aria-describedby={undefined}
                >
                    <DialogTitle>N8N HTTP Request</DialogTitle>
                    <DialogDescription>
                        <p className="text-sm">
                            The N8N HTTP Request has been copied to your clipboard. You can now paste (CTRL+V or CMD+V)
                            it into your N8N workflow.
                        </p>
                        <img src="/paste-http-module-n8n.gif" className="w-2/3 mx-auto mt-6 rounded-md" />
                    </DialogDescription>
                </DialogContent>
            </Dialog>
        </div>
    )
}
