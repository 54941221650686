import { useRevealCompany } from '@/components/hooks/company/use-reveal-company'
import CompanyModal from '@/components/ui/company/company-modal/company-modal'
import { ConfirmRevealCompanyDialog } from '@/components/ui/dialog/confirm-reveal-company-dialog'
import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import OutOfCreditsDialog from '@/components/ui/dialog/out-of-credits-dialog'
import { FullCompany } from '@/schemas/entities/full-company.schema'
import { WithTooltip } from '@/shared/ui/base/tooltip'
import { formatCompactNumber } from '@/shared/utils/number-utils'
import { useRef } from 'react'

export default function TechnologyColumn({
    company,
    updateCompany,
}: {
    company: FullCompany
    updateCompany: (company: FullCompany) => void
}) {
    const outOfCreditsDialogRef = useRef<OpenDialogHandle>(null)
    const { revealCompany } = useRevealCompany({
        company,
        updateCompany,
        postRevealAction: () => companyModalRef?.current?.open(),
        showUpgradeDialog: () => outOfCreditsDialogRef.current?.open(),
    })
    const companyModalRef = useRef<OpenDialogHandle>(null)
    const n_tecnologies = company.technology_slugs?.length || 0
    return (
        <>
            <div className="flex items-center space-x-1 align-middle">
                {n_tecnologies - company.technologies_found?.length > 0 && (
                    <>
                        <CompanyModal ref={companyModalRef} company={company} default_tab="technologies" />
                        {company.has_blurred_data == true && (
                            <ConfirmRevealCompanyDialog confirmAction={revealCompany} n_companies={1}>
                                <p className="max-w-[300px] text-clip font-normal hover:underline cursor-pointer">
                                    {formatCompactNumber(company.technology_slugs?.length || 0)}
                                </p>
                            </ConfirmRevealCompanyDialog>
                        )}
                        {company.has_blurred_data == false && (
                            <WithTooltip title="View all technologies">
                                <button className="cursor-pointer" onClick={() => companyModalRef.current?.open()}>
                                    <p className="max-w-[300px] text-clip font-normal hover:underline">
                                        {formatCompactNumber(company.technology_slugs?.length || 0)}
                                    </p>
                                </button>
                            </WithTooltip>
                        )}
                    </>
                )}
            </div>

            <OutOfCreditsDialog ref={outOfCreditsDialogRef} />
        </>
    )
}
