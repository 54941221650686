import { Table } from '@tanstack/react-table'

import { z } from 'zod'

import { OutOfCreditsException } from '@/components/exceptions/out-of-credits.exception'
import { Progress } from '@/components/ui/base/progress'
import { useToast } from '@/components/ui/base/use-toast'
import { download_csv } from '@/lib/csv-utils'
import { download_excel } from '@/lib/excel-utils'
import { EVENT_EXPORT_CLICK, sendEvent } from '@/services/tracking.service'
import { Spinner } from '@/shared/ui/base/spinner'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { ExportForm, ExportFormSchema } from './export-form'
import { useCalculateOperationCreditsCost } from './use-calculate-operation-credits-cost'

export type DataToExport = { records: string[][]; columns_with_links: number[] }

const BATCH_SIZE = 200

export function ExportDialogContent<R>({
    table,
    recordName,
    reportName,
    closeDialog,
    openOutOfCreditsDialog,
    fromRecordToCSVLines,
    isRowBlurred,
    getUniqueCompanyId,
}: {
    table: Table<R>
    recordName: string
    reportName: string
    closeDialog: () => void
    openOutOfCreditsDialog: () => void
    fromRecordToCSVLines: (records: R[]) => DataToExport
    isRowBlurred: (row: R) => boolean
    getUniqueCompanyId: (row: R) => string
}) {
    const [isFetchingDataToExport, setIsFetchingDataToExport] = useState(false)
    const [progress_percentage, setProgressPercentage] = useState(0)
    const queryClient = useQueryClient()
    const { operationCreditsCost, isCalculatingCreditsCost } = useCalculateOperationCreditsCost({
        table,
        limit: table.options?.meta?.getTotalResults() || 0,
    })
    const { toast } = useToast()

    const onSubmit = async (data: z.infer<typeof ExportFormSchema>) => {
        const pageSize = table.getState().pagination.pageSize
        let numberOfRecordsToExport = 0
        let records: R[] = []

        if (data.numberOfRecords === 'this_page') {
            records = table.getRowModel().rows.map((row) => row.original)
            numberOfRecordsToExport = records.length
        } else if (data.numberOfRecords === 'selection') {
            records = table.getSelectedRowModel().rows.map((row) => row.original)
            numberOfRecordsToExport = records.length
        } else if (data.numberOfRecords === 'all') {
            records = table.getRowModel().rows.map((row) => row.original)
            numberOfRecordsToExport = table.options?.meta?.getTotalResults() || 0
        } else if (data.numberOfRecords === 'custom') {
            records = table
                .getRowModel()
                .rows.slice(0, data.custom_number_records)
                .map((row) => row.original)
            numberOfRecordsToExport = data.custom_number_records || 0
        }

        if (data.format === 'webhook') {
            await exportToWebhook(data, numberOfRecordsToExport)
        } else {
            await exportCSVOrXLSX(data, records, numberOfRecordsToExport, pageSize)
        }

        sendEvent(EVENT_EXPORT_CLICK, { format: data.format, type: recordName })
        closeDialog()
        setIsFetchingDataToExport(false)
    }

    const exportCSVOrXLSX = async (
        data: z.infer<typeof ExportFormSchema>,
        records: R[],
        numberOfRecordsToExport: number,
        pageSize: number
    ) => {
        const isAnyRecordBlurred = records.some((row) => isRowBlurred(row))
        if (numberOfRecordsToExport > pageSize || isAnyRecordBlurred) {
            try {
                records = await fetchRecords(numberOfRecordsToExport)
            } catch (error) {
                setIsFetchingDataToExport(false)
                closeDialog()
                queryClient.invalidateQueries({ queryKey: ['me'] })
                if (error instanceof OutOfCreditsException) {
                    openOutOfCreditsDialog()
                }
                return
            }
        }
        const now = new Date()
        const localDateString = now.toLocaleDateString('en-CA') // YYYY-MM-DD format
        const localTimeString = now
            .toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })
            .replace(':', '-') // HHMM format
        const filename = `${reportName}_${localDateString}_${localTimeString}`

        if (data.format === 'xlsx') {
            const data_to_export = fromRecordToCSVLines(records)
            download_excel(data_to_export.records, filename, data_to_export.columns_with_links)
        } else if (data.format === 'csv') {
            const data_to_export = fromRecordToCSVLines(records)
            download_csv(data_to_export.records, filename)
        }
    }

    const exportToWebhook = async (data: z.infer<typeof ExportFormSchema>, numberOfRecordsToExport: number) => {
        if (!table.options?.meta?.exportToWebhook) return []
        await table.options?.meta?.exportToWebhook(data.webhook_url || '', numberOfRecordsToExport)
        toast({
            title: 'Export started',
            description: 'Your export has been started',
        })
    }

    const fetchRecords = async (numberOfRecords: number): Promise<R[]> => {
        if (!table.options?.meta?.fetchAndAddRowsIncrementally) return []
        setIsFetchingDataToExport(true)
        const total_records = numberOfRecords
        const total_batches = Math.ceil(total_records / BATCH_SIZE)
        for (let i = 0; i < total_batches; i++) {
            const offset = i * BATCH_SIZE
            const limit = Math.min(BATCH_SIZE, total_records - offset)
            const response = await table.options?.meta?.fetchAndAddRowsIncrementally(offset, limit)
            setProgressPercentage(((i + 1) / total_batches) * 100)
            if (response.is_out_of_credits) {
                throw new OutOfCreditsException('You have run out of credits')
            }
            await new Promise((resolve) => setTimeout(resolve, 500))
        }
        queryClient.invalidateQueries({ queryKey: ['me'] })
        return table.options?.meta?.getBulkSelectionData()
    }

    return (
        <>
            {isCalculatingCreditsCost && (
                <div className="flex items-center justify-center">
                    <Spinner />
                </div>
            )}
            {!isFetchingDataToExport && !isCalculatingCreditsCost && operationCreditsCost != undefined && (
                <ExportForm
                    table={table}
                    recordName={recordName}
                    all_records_operation_cost={operationCreditsCost}
                    onSubmit={onSubmit}
                    onCancel={closeDialog}
                    isRowBlurred={isRowBlurred}
                    getUniqueCompanyId={getUniqueCompanyId}
                />
            )}
            {isFetchingDataToExport && (
                <>
                    <p className="text-sm text-muted-foreground">
                        Downloading records, this may take a few minutes. Please wait.
                    </p>
                    <Progress value={progress_percentage} />
                </>
            )}
        </>
    )
}
