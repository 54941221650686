import { useCompanyAction } from '@/components/hooks/data-table/use-company-action'
import { getJobCurrentJobTitles } from '@/pages/settings/integrations/integrations.page'
import { EVENT_FIND_PEOPLE_CLICK, sendEvent } from '@/services/tracking.service'
import { Company } from '@/shared/schemas/entity/company.schema'
import { Button } from '@/shared/ui/base/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/shared/ui/base/dropdown-menu'
import { Table } from '@tanstack/react-table'
import { UsersRound } from 'lucide-react'
import { WithTooltip } from '../../../../shared/ui/base/tooltip'

type ConnectIntegations = 'apollo' | 'contactout' | 'linkedin' | 'linkedin-recruiter' | 'linkedin-sales-navigator'

export default function FindPeopleButton<TData>({
    isDataFetching,
    table,
    fromRowToCompany,
}: {
    isDataFetching: boolean
    table: Table<TData>
    fromRowToCompany: (rows: TData[]) => Company[]
}) {
    const { isActionDisabled, disableReason, companies } = useCompanyAction(isDataFetching, table, fromRowToCompany)

    const findPeople = (app: ConnectIntegations) => {
        const url = getFindPeopleUrl(app, companies)
        window.open(url, '_blank', 'noreferrer,noopener')
    }

    const button = (
        <Button variant="ghost" size="sm" className="text-sm font-normal" disabled={isActionDisabled}>
            <UsersRound className="h-4 w-4 md:mr-2" />
            <p className="hidden lg:inline">Find people</p>
        </Button>
    )

    const getFindPeopleUrl = (app: ConnectIntegations, companies: Company[]) => {
        sendEvent(EVENT_FIND_PEOPLE_CLICK, { destination_app: app })
        const titles = getJobCurrentJobTitles()
        const titles_string = titles.join(' OR ')

        if (app === 'apollo') {
            const apollo_ids = companies.map((x) => x.apollo_id).filter((x) => x != null && x != '')
            const unique_apollo_ids = apollo_ids.filter((value, index, self) => self.indexOf(value) === index)
            return (
                'https://app.apollo.io/#/people?page=1&personNotTitles[]=product%20owner&personSeniorities[]=owner&personSeniorities[]=founder&personSeniorities[]=c_suite&personSeniorities[]=vp&personSeniorities[]=head&personSeniorities[]=director&' +
                titles_string +
                '&' +
                unique_apollo_ids.map((x) => 'organizationIds[]=' + x).join('&')
            )
        } else if (app === 'linkedin-recruiter') {
            const linkedin_ids = companies.map((x) => x.linkedin_id).filter((x) => x != null && x != '')
            const unique_linkedin_ids = linkedin_ids.filter((value, index, self) => self.indexOf(value) === index)
            return (
                'https://www.linkedin.com/talent/search?searchFacets=' +
                encodeURIComponent(JSON.stringify({ CURRENT_COMPANY: unique_linkedin_ids })) +
                '&searchKeyword=' +
                encodeURI(titles_string)
            )
        } else if (app === 'linkedin-sales-navigator') {
            const name_and_linkedinid = companies
                .map((x) => ({ linkedin_id: x.linkedin_id, company_name: x.name }))
                .filter((x) => x.linkedin_id != null && x.linkedin_id != '')
            const unique_name_and_linkedinid = name_and_linkedinid.filter(
                (value, index, self) => self.findIndex((x) => x.linkedin_id === value.linkedin_id) === index
            )
            return (
                'https://www.linkedin.com/sales/search/people?query=' +
                encodeURIComponent(
                    '(spellCorrectionEnabled:true,filters:List(' +
                        [
                            '(type:CURRENT_COMPANY,values:List(' +
                                unique_name_and_linkedinid
                                    .map((x) =>
                                        x.linkedin_id
                                            ? `(id:${x.linkedin_id},text:${encodeURIComponent(x.company_name)},selectionType:INCLUDED)`
                                            : `(text:${encodeURIComponent(x.company_name)},selectionType:INCLUDED)`
                                    )
                                    .join(',') +
                                '))',
                            '(type:CURRENT_TITLE,values:List(' +
                                titles
                                    .map((x: string) => `(text:${encodeURIComponent(x)},selectionType:INCLUDED)`)
                                    .join(',') +
                                '))',
                        ].join(',') +
                        '))'
                )
            )
        } else if (app === 'linkedin') {
            const linkedin_ids = companies.map((x) => x.linkedin_id).filter((x) => x != null && x != '')
            const unique_linkedin_ids = linkedin_ids.filter((value, index, self) => self.indexOf(value) === index)
            return (
                'https://www.linkedin.com/search/results/people/?currentCompany=' +
                encodeURIComponent(JSON.stringify(unique_linkedin_ids)) +
                '&titleFreeText=' +
                encodeURI(titles_string)
            )
        } else if (app === 'contactout') {
            const company_names = companies.map((x) => x.name).filter((x) => x != null && x != '')
            const unique_company_names = company_names.filter((value, index, self) => self.indexOf(value) === index)
            return (
                'https://contactout.com/dashboard/search?company=' +
                encodeURIComponent(unique_company_names.join('|')) +
                '&title=' +
                encodeURIComponent(titles_string.split(' OR ').join('|'))
            )
        }
        return undefined
    }

    return (
        <>
            {isActionDisabled && (
                <WithTooltip title="Find people on" message={disableReason}>
                    {' '}
                    {button}{' '}
                </WithTooltip>
            )}
            {!isActionDisabled && (
                <DropdownMenu>
                    <WithTooltip title="Find people on">
                        <DropdownMenuTrigger asChild>{button}</DropdownMenuTrigger>
                    </WithTooltip>
                    <DropdownMenuContent align="start">
                        <DropdownMenuLabel>Find people on ({companies.length} companies)</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onSelect={() => findPeople('apollo')} disabled={companies.length === 0}>
                            Apollo.io
                        </DropdownMenuItem>
                        <DropdownMenuItem onSelect={() => findPeople('contactout')} disabled={companies.length === 0}>
                            ContactOut.com
                        </DropdownMenuItem>
                        <DropdownMenuItem onSelect={() => findPeople('linkedin')} disabled={companies.length === 0}>
                            LinkedIn
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onSelect={() => findPeople('linkedin-recruiter')}
                            disabled={companies.length > 100 || companies.length === 0}
                        >
                            LinkedIn Recruiter {companies.length > 100 ? '(max 100 records)' : ''}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onSelect={() => findPeople('linkedin-sales-navigator')}
                            disabled={companies.length > 100 || companies.length === 0}
                        >
                            LinkedIn Sales Navigator {companies.length > 100 ? '(max 100 records)' : ''}
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </>
    )
}
