import { OpenDialogHandle } from '@/components/ui/dialog/dialog-handle-interface'
import { Card } from '@/shared/ui/base/card'
import { BriefcaseBusinessIcon, Building2, FolderSync, Target } from 'lucide-react'
import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { EnrichDialog } from './enrich-dialog'

export default function StartNewSearch() {
    const navigate = useNavigate()
    const enrichDialogRef = useRef<OpenDialogHandle>(null)

    const type_of_searches = [
        {
            name: 'Search Jobs',
            description: 'Across 50M+ jobs worldwide from Linkedin, Indeed, Workable, Greenhouse, etc.',
            action: () => navigate('/search/jobs/new'),
            icon: BriefcaseBusinessIcon,
        },
        {
            name: 'Search Companies',
            description: 'Filtering by technologies they use, job openings, industries, size, revenue, etc.',
            action: () => navigate('/search/companies/new'),
            icon: Building2,
        },
        {
            name: 'Look up a company',
            description: 'Jump straight to the details of a company: technologies, jobs, size, etc.',
            action: () => openSearchBar(),
            icon: Target,
        },
        {
            name: 'Enrich company list',
            description: 'Download the tech stack for a list of company websites or names.',
            action: () => enrichDialogRef.current?.open(),
            icon: FolderSync,
        },
    ]
    const openSearchBar = () => {
        document.dispatchEvent(
            new KeyboardEvent('keydown', {
                key: 'k',
                metaKey: true,
            })
        )
    }
    return (
        <>
            <div className="flex flex-col gap-2">
                <p className="text-sm text-gray-700"> Start a new search</p>
                <div className="grid grid-cols-2 md:grid-cols-4 md:gap-2 gap-1">
                    {type_of_searches.map((search) => (
                        <Card
                            key={search.name}
                            className="p-2 md:p-4 flex flex-col gap-2 hover:bg-gray-100 max-w-[300px] cursor-pointer"
                            onClick={search.action}
                        >
                            <div className="flex gap-2 items-center">
                                <div className="bg-secondary rounded-md">
                                    {React.createElement(search.icon, {
                                        strokeWidth: 1.5,
                                        className: 'w-5 h-5 m-1 text-primary',
                                    })}
                                </div>
                                <p className="text-base">{search.name}</p>
                            </div>
                            <p className="text-xs text-gray-500">{search.description}</p>
                        </Card>
                    ))}
                </div>
            </div>
            <EnrichDialog ref={enrichDialogRef} />
        </>
    )
}
