import { DotsHorizontalIcon } from '@radix-ui/react-icons'
import { Row } from '@tanstack/react-table'

import { CompanyFromList } from '@/schemas/entities/companies-from-company-list'
import { CompanyList } from '@/schemas/entities/company-list.schema'
import { getCompanyList, removeCompanyToCompanyList } from '@/services/company_list.service'
import { Button } from '@/shared/ui/base/button'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/shared/ui/base/dropdown-menu'
import { HOUR_IN_MS } from '@/shared/utils/date-utils'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

interface DataTableRowActionsProps {
    row: Row<CompanyFromList>
}

export function DataTableRowActions({ row }: DataTableRowActionsProps) {
    const navigate = useNavigate()
    const { listId } = useParams<{ listId: string }>()

    const { data: list } = useQuery<CompanyList>({
        queryKey: ['companyList', listId],
        queryFn: () => getCompanyList(+(listId || 0)),
        enabled: !!listId,
        staleTime: HOUR_IN_MS,
    })

    const handleDelete = () => {
        if (!listId) return
        removeCompanyToCompanyList(+listId, [row.original.company_name]).then(() => {
            navigate(0)
        })
    }

    return (
        <>
            {list && list.name != 'Companies seen' && (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
                            <DotsHorizontalIcon className="h-4 w-4" />
                            <span className="sr-only">Open menu</span>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[160px]">
                        <DropdownMenuItem onClick={handleDelete}>Remove</DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </>
    )
}
