import { Technology } from '@/schemas/entities/technology.schema'
import { getTechnologies, getTechnologiesByName, getTechnologiesBySlugs } from '@/services/technology.service'
import { Spinner } from '@/shared/ui/base/spinner'
import { Cross2Icon } from '@radix-ui/react-icons'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import debouce from 'lodash.debounce'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import { Tag } from '../../search-filter/components/tag'
import { CheckBoxItem } from './components/checkbox-item'

interface SelectTechnologiesProps {
    value: string[]
    onChange?: (selectedTechnologies: string[]) => void
}

export function MultiSelectTechnologies({ value, onChange }: SelectTechnologiesProps) {
    const [searchQuery, setSearchQuery] = useState('')
    const { isIntersecting: isVisible, ref: loadingButtonRef } = useIntersectionObserver({ threshold: 0.5 })
    const [selectedItems, setSelectedItems] = useState<string[]>(value || [])
    const { data: originalSelectedTechnologies = [] } = useQuery<Technology[]>({
        queryKey: ['technologies', 'slug', '0', value],
        queryFn: () => {
            return getTechnologiesBySlugs(value)
        },
        staleTime: Infinity,
    })
    const [selectedTechnologies, setSelectedTechnologies] = useState<Technology[]>(originalSelectedTechnologies)
    const {
        data: filteredTechnologies = [],
        status: selectedTechnologiesStatus,
        refetch: refetchAllTechnologies,
    } = useQuery<Technology[]>({
        queryKey: ['technologies', 'name', '0', searchQuery],
        queryFn: () => {
            return getTechnologiesByName(searchQuery)
        },
        staleTime: Infinity,
        enabled: searchQuery !== '',
    })
    const {
        data: allTechnologies,
        status: allTechnologiesStatus,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteQuery<Technology[]>({
        queryKey: ['technologies'],
        queryFn: ({ pageParam }) => getTechnologies(pageParam as number, 10),
        staleTime: Infinity,
        getNextPageParam: (lastPage, allPages) => (lastPage.length === 10 ? allPages.length : undefined),
        initialPageParam: 0,
    })

    useEffect(() => {
        if (isVisible && hasNextPage) {
            fetchNextPage()
        }
    }, [isVisible])

    const handleChange = (e: any) => {
        setSearchQuery(e.target.value)
    }

    const debouncedResults = useMemo(() => {
        return debouce(handleChange, 300)
    }, [])

    useEffect(() => {
        return () => {
            debouncedResults.cancel()
        }
    })

    useEffect(() => {
        if (searchQuery !== '') {
            refetchAllTechnologies()
        }
    }, [searchQuery])

    useEffect(() => {
        onChange?.(selectedItems)
    }, [selectedTechnologies])

    return (
        <div className="grid items-center gap-y-4">
            <div
                className={`relative flex flex-col items-start border rounded max-h-40 overflow-y-auto ${
                    selectedTechnologies.length > 0 ? 'p-2' : 'px-3'
                }`}
                cmdk-input-wrapper=""
            >
                <div className="flex flex-row gap-1 flex-wrap">
                    {selectedTechnologies.map((item: Technology) => (
                        <Tag
                            key={item.slug}
                            id={item.slug}
                            name={item.name}
                            img={item.logo || ''}
                            onClick={(slug_to_remove) => {
                                setSelectedTechnologies(
                                    selectedTechnologies.filter((item) => item.slug !== slug_to_remove)
                                )
                                setSelectedItems(selectedItems.filter((slug) => slug !== slug_to_remove))
                            }}
                        />
                    ))}
                    <input
                        type="text"
                        autoFocus={true}
                        onChange={debouncedResults}
                        placeholder="Search..."
                        className="h-8 min-w-12 rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                    />
                </div>
                {selectedTechnologies.length > 0 && (
                    <Cross2Icon
                        className="absolute right-2 top-3 h-4 w-4 cursor-pointer text-slate-500 hover:text-black"
                        onClick={() => {
                            setSelectedItems([])
                            setSelectedTechnologies([])
                        }}
                    />
                )}
            </div>
            <div className="flex flex-col max-h-[200px] overflow-scroll gap-1">
                {((searchQuery === '' && allTechnologiesStatus === 'pending') ||
                    (searchQuery != '' && selectedTechnologiesStatus === 'pending')) && (
                    <div className="flex justify-center pt-2 pb-4">
                        <Spinner size="sm" />
                    </div>
                )}
                {searchQuery !== '' &&
                    filteredTechnologies.length === 0 &&
                    selectedTechnologiesStatus === 'success' && (
                        <div className="flex justify-center pt-2 pb-4">
                            <p className="text-sm text-gray-500 text-center">No results found for "{searchQuery}"</p>
                        </div>
                    )}
                {searchQuery !== '' &&
                    filteredTechnologies
                        .filter((item) => !selectedTechnologies.some((tech) => tech.slug === item.slug))
                        .map((item) => (
                            <CheckBoxItem
                                key={item.slug}
                                item={item}
                                checked={selectedItems.includes(item.slug)}
                                onChange={(checked) => {
                                    if (checked) {
                                        setSelectedItems([...selectedItems, item.slug])
                                        setSelectedTechnologies([...selectedTechnologies, item])
                                    } else {
                                        setSelectedItems(selectedItems.filter((slug) => slug !== item.slug))
                                        setSelectedTechnologies(
                                            selectedTechnologies.filter((tech) => tech.slug !== item.slug)
                                        )
                                    }
                                }}
                            />
                        ))}
                {searchQuery == '' && allTechnologiesStatus === 'success' && (
                    <>
                        {allTechnologies.pages.map((group, i) => (
                            <React.Fragment key={i}>
                                {group
                                    .filter(
                                        (item) =>
                                            !selectedTechnologies.some((technology) => technology.slug === item.slug)
                                    )
                                    .map((item) => (
                                        <CheckBoxItem
                                            key={item.slug}
                                            item={item}
                                            checked={selectedItems.includes(item.slug)}
                                            onChange={(checked) => {
                                                if (checked) {
                                                    setSelectedItems([...selectedItems, item.slug])
                                                    setSelectedTechnologies([...selectedTechnologies, item])
                                                } else {
                                                    setSelectedItems(selectedItems.filter((slug) => slug !== item.slug))
                                                    setSelectedTechnologies(
                                                        selectedTechnologies.filter((tech) => tech.slug !== item.slug)
                                                    )
                                                }
                                            }}
                                        />
                                    ))}
                            </React.Fragment>
                        ))}
                        <div ref={loadingButtonRef} className="flex justify-center">
                            <Spinner size="sm" className="m-1" />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
