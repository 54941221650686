import { Column, Table } from '@tanstack/react-table'

import { Button } from '@/shared/ui/base/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/ui/base/popover'

import { TECHNOLOGY_COLUMN_PREFIX } from '@/pages/search/companies/components/columns-company'
import { Separator } from '@/shared/ui/base/separator'
import { ArrowLeft, Columns3, Plus } from 'lucide-react'
import { useState } from 'react'
import { Switch } from '../base/switch'
import { MultiSelectTechnologies } from '../form/multi-select-technologies/multi-select-technologies'

interface DataTableViewOptionsProps<TData> {
    table: Table<TData>
}

type DataTableViewOptionsPage = 'home' | 'technology'

interface DataTableViewOptionsState<TData> extends DataTableViewOptionsProps<TData> {
    setPage: (page: DataTableViewOptionsPage) => void
    close: () => void
}

export function DataTableViewOptions<TData>({ table }: DataTableViewOptionsProps<TData>) {
    const [page, setPage] = useState<DataTableViewOptionsPage>('home')
    const [open, setOpen] = useState(false)
    const showTechnologyPage = table.options.meta?.addDynamicColumns !== undefined
    const handleOnOpenChange = (open: boolean) => {
        if (!open) {
            handleClose()
        }
        setOpen(open)
    }

    const handleClose = () => {
        setPage('home')
        setOpen(false)
    }

    return (
        <Popover open={open} onOpenChange={handleOnOpenChange}>
            <PopoverTrigger asChild>
                <Button variant="ghost" size="sm" className="hover:bg-gray-200">
                    <Columns3 className="h-4 w-4 mr-1" /> Columns
                </Button>
            </PopoverTrigger>
            <PopoverContent align="end" className="w-[300px] p-3 max-h-[50vh] overflow-y-auto">
                {page === 'home' && <HomeViewOptions table={table} setPage={setPage} close={handleClose} />}
                {page === 'technology' && showTechnologyPage && (
                    <TechnologyViewOptions table={table} setPage={setPage} close={handleClose} />
                )}
            </PopoverContent>
        </Popover>
    )
}

function ColumnName({ column }: { column: Column<any> }) {
    const name = column.columnDef?.meta?.name()
    return <p className="text-sm font-normal capitalize">{name}</p>
}

function HomeViewOptions<TData>({ table, setPage, close }: DataTableViewOptionsState<TData>) {
    const isDynamicColumn = (id: string) => {
        return id.startsWith(TECHNOLOGY_COLUMN_PREFIX)
    }

    const handleColumnToggle = (column: Column<TData>) => {
        if (isDynamicColumn(column.id)) {
            table.options.meta?.removeDynamicColumns?.([column.id])
        } else {
            column.toggleVisibility()
        }
        close()
    }

    return (
        <>
            {table.options.meta?.addDynamicColumns !== undefined && (
                <>
                    <Button
                        className="capitalize font-normal mb-2 w-full justify-start"
                        variant="ghost"
                        size="xs"
                        onClick={() => setPage('technology')}
                    >
                        <Plus className="h-4 w-4 mr-2" /> New technology column
                    </Button>
                    <Separator className="mb-2" />
                </>
            )}
            <div className="flex flex-col gap-1 items-start">
                {table
                    .getAllColumns()
                    .filter((column) => typeof column.accessorFn !== 'undefined' && column.getCanHide())
                    .map((column) => {
                        return (
                            <div key={column.id} className="flex items-center justify-between w-full">
                                <ColumnName column={column} />
                                <Switch
                                    checked={column.getIsVisible()}
                                    onCheckedChange={() => handleColumnToggle(column)}
                                />
                            </div>
                        )
                    })}
            </div>
        </>
    )
}

function TechnologyViewOptions<TData>({ table, setPage, close }: DataTableViewOptionsState<TData>) {
    const [selectedTechnologies, setSelectedTechnologies] = useState<string[]>([])

    const onAdd = () => {
        const technology_ids = selectedTechnologies.map((technology) => TECHNOLOGY_COLUMN_PREFIX + technology)
        table.options.meta?.addDynamicColumns?.(technology_ids)
        close()
    }

    return (
        <>
            <PageHeader title="Add technology column" showBackButton={true} setPage={setPage} />
            <MultiSelectTechnologies value={selectedTechnologies} onChange={setSelectedTechnologies} />
            <Button className="w-full" variant="default" size="sm" onClick={onAdd}>
                Add
            </Button>
        </>
    )
}

function PageHeader({
    title,
    showBackButton,
    setPage,
}: {
    title: string
    showBackButton: boolean
    setPage: (page: DataTableViewOptionsPage) => void
}) {
    return (
        <>
            <div className="flex items-center mb-1 gap-1">
                {showBackButton && (
                    <Button variant="ghost" size="icon_sm" onClick={() => setPage('home')}>
                        <ArrowLeft className="h-4 w-4" />
                    </Button>
                )}
                <h2 className="text-sm font-medium py-1">{title}</h2>
            </div>
            <Separator className="mb-2" />
        </>
    )
}
