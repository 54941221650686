import { PageLayout } from '@/components/ui/layout/page_layout'
import { NewTechnologyForm } from './components/new-technology-form'

export const NewTechnologyPage = () => {
    return (
        <PageLayout className="mt-10">
            <NewTechnologyForm />
        </PageLayout>
    )
}
